import React from "react";
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import Subscribe from '../../components/Subscribe'
import Footer from '../../components/Footer'

export default class SubscribePage extends React.Component {
  render() {

    return (

      <Layout>
        <Helmet
          titleTemplate="%s | Simon Koelewijn"
        >
          <title>Abonneren</title>
          <meta name="description" content="Blog van Simon Koelewijn over optimalisatie, analytics &amp; UX." />
        </Helmet>
        <section className="section">
          <div className="container container-small">
            <Subscribe/>
          </div>
          <div className="container container-small">
            <Footer/>
          </div>
        </section>
      </Layout>

    );
  }
}